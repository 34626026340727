<template>
    <div>
        <b-card class="mt-2">
            <h1>Team</h1>
        </b-card>
        <b-card class="mt-2">
            <b-button v-if="this.$store.state.auth.user.main_contact_user" @click="openModal = true" align="center" class="mb-4" variant="primary">{{ $t('add_member') }}
            </b-button>

            <b-table
                :fields="companyMemberFields"
                :items="team"
                responsive
                sticky-header
                style="height: 400px; overflow-y: auto"
            >
                <template #cell(role)="data">
                    <span>{{ $t(data.item.role) }}</span>
                </template>
                <template #cell(action)="data">
                    <b-button :title="$t('edit')" @click="openEditModalFunction(data.item)" class="button-space mr-1" size="sm" v-b-tooltip.hover variant="outline-primary">
                        <feather-icon icon="EditIcon"></feather-icon>
                    </b-button>
                    <b-button :title="$t('delete')" @click="destroyMember(data.item)" class="button-space" size="sm" v-b-tooltip.hover variant="outline-primary">
                        <feather-icon icon="TrashIcon"></feather-icon>
                    </b-button>
                </template>
            </b-table>
        </b-card>

        <!-- create modal -->
        <b-modal @ok="sendEmail" scrollable size="md" style="padding-top: 20vh;" v-model="openModal">
            <b-form-group :label="$t('email')" label-for="email">
                <validation-provider #default="{ errors }" name="email" rules="email">
                    <b-form-input id="email" placeholder="john@example.com" v-model.trim="requestData.email"/>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-select
                :options="roleOptions"
                id="role"
                name="role"
                v-model="requestData.role"
            ></b-form-select>
        </b-modal>

        <!-- edit modal -->
        <b-modal @ok="changeRole" scrollable size="md" style="padding-top: 20vh;" v-model="openEditModal">
            <b-form-select
                :options="roleOptions"
                id="edit_role"
                name="edit_role"
                v-model="requestEditData.role"
            ></b-form-select>
        </b-modal>

    </div>
</template>

<script>

  import {ValidationProvider} from "vee-validate";
  import {BButton, BFormGroup, BFormInput, BFormSelect, BModal,} from 'bootstrap-vue';
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    components: {
      BButton,
      BModal,
      BFormGroup,
      BFormInput,
      BFormSelect,
      ValidationProvider
    },
    data() {
      return {
        team: null,
        openModal: false,
        openEditModal: false,
        editItemID: null,
        roleOptions: [
          {value: 'full_access', text: this.$t('full_access')},
          {value: 'restricted_access', text: this.$t('restricted_access')},
        ],
        requestData: {
          email: null,
          role: 'full_access'
        },

        requestEditData: {
          role: 'full_access'
        },

        companyMemberFields: [
          {
            label: "id",
            align: "start",
            key: "id",
          },
          {
            label: "full name",
            align: "start",
            key: "full_name",
          },
          {
            label: "email",
            align: "start",
            key: "email",
          },
          {
            label: "role",
            align: "start",
            key: "role",
          },
          {
            label: "acton",
            align: "start",
            key: "action",
          },
        ],

      }
    },

    created() {
      console.log(this.$store.state.auth.user, 123456);
      this.get_members();
      console.log(this.$store.state.auth.user)
    },

    methods: {

      get_members() {
        this.$http
            .post('team')
            .then((res) => {
              if (res) {
                this.team = res.data.value
                console.log(this.team)
              }
            });
      },

      sendEmail() {
        this.$http
            .post('team/send_email', this.requestData)
            .then((res) => {
              if (res) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("invited_send_successfully"),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
              }
              setTimeout(() => {
                window.location.reload();
              }, 100);
            });
      },

      openEditModalFunction(item) {
        this.requestEditData.role = item.role;
        this.editItemID = item.id;
        this.openEditModal = true;
      },

      changeRole() {
        this.$http
            .post(`team/change_role/${this.editItemID}`, this.requestEditData)
            .then((res) => {
              if (res) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("member_role_change_successfully"),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
              }
              setTimeout(() => {
                window.location.reload();
              }, 100);
            });
      },
      destroyMember(item) {
        this.$http
            .post(`team/destroy/${item.id}`)
            .then((res) => {
              if (res) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("member_remove_successfully"),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
              }
              setTimeout(() => {
                window.location.reload();
              }, 100);
            });
      }
    }

  }
</script>

<style scoped>

</style>